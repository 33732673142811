import { isWithinInterval } from 'date-fns'

// Non-filtered products used for soft-closed proudcts haX (classic, family, jump)
export const haXProducts = {}

export const VITAL_PRODUCTS = ['SAMB06SProdukt', 'SAMB06MProdukt', 'SAMB06LProdukt']
export const SOFT_CLOSED_PRODUCTS = ['AB04Produkt', 'AB304Produkt', 'AB404Produkt']

// TODO: clean up as this period is in the past
// Recommendations should not be shown in dec 2023
// FLEX-2892
export function isInBlockedVitalTimeframe() {
  const start = new Date(2023, 11, 1)
  const end = new Date(2023, 11, 31)

  return isWithinInterval(new Date(), { start, end })
}

export function isProductReadOnlyHaX(productId) {
  return SOFT_CLOSED_PRODUCTS.includes(productId)
}

/**
 *  preProductPopulationHaX removes the following additional insurances during the soft close phase:
 *  (soft close phase for these products is prolonged for the foreseeable future)
 *  - AB04Produkt (Classic)
 *  - AB404Produkt (Family)
 *  - AB304Produkt (Jump)
 *
 *  @param {ProductStructure} structure - Make sure the structure is cloned before passing it as a parameter
 *  @return {ProductStructure} structure
 */
export function filterSoftCloseProductsHaX(structure) {
  // Filter out "soft-closed" Classic/Family/Jump/Easy,
  if (structure.groups['ADDITIONAL_CLASSIC']) {
    structure.groups['ADDITIONAL_CLASSIC'].products = structure.groups['ADDITIONAL_CLASSIC'].products.filter(
      p => !SOFT_CLOSED_PRODUCTS.includes(p)
    )
  }
  SOFT_CLOSED_PRODUCTS.forEach(id => {
    delete structure.products[id]
  })

  return structure
}

// FLEX-2967
// DOTCOM-539
export function isOnVitalCampaignPageWhileLogin() {
  return !!new URLSearchParams(window.location.search)?.get('dvp_vital_campaign')
}

/*
 *  Add product as an existing customer (really existing, not newly added to an existing basket)
 *  -----------------------------------
 *
 *  1. Get productId and group from product the person wants to add
 *  2. Get all productIds from the products in the contract for that person
 *  3. Figure out the group for each of these productIds from the contract  (for that we need the original product structure)
 *  4. Check if there are productIds within the same group as the newly added product
 *  5a. If there's none, no further action
 *  5b. If there are productIds and the group's 'exclusive' flag is set to true, remove the existing product (as there can only be one) from the basket
 *  5c. If there are productIds and the group's 'exclusive' flag is set to false, no further action *
 *  6. Add newly added product to basket
 *  7. Profit
 *
 *
 *
 *  Remove product as an existing customer (really existing, not newly added to an existing basket)
 *  --------------------------------------
 *
 *  1. Get productId and group from product the person wants to remove
 *  2. Get all productIds from the products in the contract for that person
 *  3. Figure out the group for each of these productIds from the contract
 *  4. Check if there are productIds within the same group as the product the user wants to remove.
 *  5a. If there's none, no further action
 *  5b. If there are productIds and the group's 'exclusive' flag is set to true, add the existing product (as there can only be one) to the basket
 *  5c. If there are productIds and the group's 'exclusive' flag is set to false, no further action *
 *  6. Remove selected product from basket
 *  7. Profit
 *
 *  * We have to make sure, that the forbiddenProductCombinations are working correctly with 'contractProducts' as well
 */
