import { computed } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash'

import events$ from '@/services/Events'
import { createDistributionPartners } from '@/helpers/Partner'

import basketStore from '@/store/basket'
import contractStartDateAPI from '@/api/contractStartDate'
import productAPI from '@/api/product'
import useI18n from '@/hooks/useI18n'

import { CHANNEL, DEFAULT_DISTRIBUTION_PARTNER_NUMBER, DEFAULT_LANGUAGE } from '@/config/constants'
import { EVENT_LANGUAGE } from '@/config/events'

export default function useBasket() {
  // HOOKS
  const { updateTranslations, selectedLanguage } = useI18n()

  // COMPUTED
  const contractData = computed(() => {
    return {
      address: basketStore.basket.address,
      contractStartDate: basketStore.basket.contractStartDate,
    }
  })

  function hasImmigratedPerson(__basket) {
    return __basket.persons?.some(p => p.immigrant)
  }

  const isExistingCustomerBasket = computed(() => {
    return basketStore.basket.existingCustomer
  })

  /**
   * isExistingCustomerBasketWithExistingCustomers returns true if the basket is from an
   * an existing customer contract and the selected persons are already existing persons within
   * that contract (instead of newly added persons)
   */
  const isExistingCustomerBasketWithExistingCustomers = computed(() => {
    if (!basketStore.basket.existingCustomer) return false

    return basketStore.basket.persons?.filter(p => p.contractModified).every(p => p.partnerNumber)
  })

  /**
   * isStaticBasket returns true for baskets of existing customers with closed products in the contract
   */
  const isStaticBasket = computed(() => {
    if (basketStore.basket.dynamic === undefined) return false
    return !basketStore.basket.dynamic
  })

  // METHODS
  /**
   *  createDefaultOffer creates a pre-defined offer for aide's one-click functionality
   *  @returns {Basket}
   */
  async function createDefaultOffer() {
    const __basket = cloneDeep(basketStore.basket)
    const productIds = await productAPI.getDefaultOfferProductIds(__basket)

    __basket.persons.forEach(person => {
      Object.keys(person.products.products).forEach(productId => {
        person.products.products[productId].selected = productIds[person.personId].includes(productId)
      })
    })
    __basket.channel = CHANNEL.ALVA
    return await basketStore.updateOvpBasket(__basket)
  }

  /**
   *  createDefaultBasket creates a minimal basket on the frontend only
   *  @param {Basket} params
   *  @returns {Basket}
   */
  async function createDefaultBasket(params) {
    const contractStartDates = await contractStartDateAPI.getDefaultContractStartDate()

    return {
      contractStartDate: contractStartDates.kvgContractStartDate,
      basketId: uuidv4(),
      distributionPartners: createDistributionPartners(DEFAULT_DISTRIBUTION_PARTNER_NUMBER),
      persons: [],
      language: selectedLanguage.value.toUpperCase() || DEFAULT_LANGUAGE,
      ...params,
    }
  }

  async function customizeContract(payload) {
    const updatePayload = { ...payload }
    return await basketStore.updateOvpBasket(updatePayload)
  }

  async function updateLanguage({ language, isAide = false, isUserTriggered = false }) {
    const _language = await updateTranslations({ language, isAide })
    const isReentry = basketStore.basket.mobile?.includes('*')

    /**
     * Exception: ReEntry. Only user triggered language change is allowed to do the Backend-Call.
     * Otherwise, a hot-basket would be created, which we don't want for tracking-reasons
     */
    if (isUserTriggered && !isReentry) {
      await basketStore.updateOvpBasket({ language: _language })
    } else {
      basketStore.persistBasket({ language: _language })
    }

    events$.emit(EVENT_LANGUAGE.LOADED, { language: _language })
  }

  /**
   * This value can change based on year
   */
  function voc(person) {
    const year = parseInt(person?.kvgContractStartDate?.substring(0, 4))

    switch (year) {
      case 2023:
        return '5.10'
      case 2024:
        return '5.35'
      case 2025:
        return '5.15'
      default:
        return '7.35'
    }
  }

  return {
    // computeds
    contractData, // computed instead of getContractData method
    isExistingCustomerBasket,
    isExistingCustomerBasketWithExistingCustomers,

    // various
    createDefaultBasket,
    createDefaultOffer,
    isStaticBasket,
    hasImmigratedPerson,
    voc,

    // writes to be
    updateLanguage,
    customizeContract,
  }
}
